import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiIntellijidea,
  SiJira,
  SiGithub,
  SiAmazonaws,
  SiDocker,
  SiKubernetes,
  SiMicrosoftoffice
} from "react-icons/si";

function Toolstack() {
  const toolLinks = {
    visualStudioCode: "https://code.visualstudio.com/",
    intelliJ: "https://www.jetbrains.com/idea/",
    postman: "https://www.postman.com/",
    slack: "https://slack.com/",
    jira: "https://www.atlassian.com/software/jira",
    github: "https://github.com/",
    aws: "https://aws.amazon.com/",
    docker: "https://www.docker.com/",
    kubernetes: "https://kubernetes.io/",
    microsoftOffice: "https://www.microsoft.com/en-us/microsoft-365"
  };

  const linkStyle = { color: "inherit", textDecoration: "none" };

  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <a href={toolLinks.visualStudioCode} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiVisualstudiocode />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={toolLinks.intelliJ} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiIntellijidea />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={toolLinks.postman} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiPostman />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={toolLinks.slack} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiSlack />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={toolLinks.jira} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiJira />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={toolLinks.github} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiGithub />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={toolLinks.aws} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiAmazonaws />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={toolLinks.docker} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiDocker />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={toolLinks.kubernetes} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiKubernetes />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={toolLinks.microsoftOffice} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiMicrosoftoffice />
        </a>
      </Col>
    </Row>
  );
}

export default Toolstack;
