import React, { useState } from "react";
import { Card, Button, Modal } from "react-bootstrap"; // Import Card, Button, and Modal from React-Bootstrap
import { CgWebsite } from "react-icons/cg"; // Import CgWebsite icon
import { FaGithub } from "react-icons/fa"; // Import FaGithub icon
import PropTypes from "prop-types"; // Import PropTypes for prop validation

function ProjectCards({ imgPath, title, description, DEMOLink, GITHUBLink }) {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div>
      {/* Card displaying project details */}
      <Card className="project-card-view">
        {/* Card image is clickable */}
        <Card.Img
          variant="top"
          src={imgPath}
          alt="card-img"
          onClick={handleShowModal}
          style={{
            cursor: "pointer",
            height: "200px", // Set a fixed height for the images
            objectFit: "cover", // Ensures the image scales properly without distortion
            borderRadius: "10px" // Rounds the corners of the image
          }}
        />
        <Card.Body>
          <Card.Title><b>{title}</b></Card.Title>
          <Card.Text style={{ textAlign: "justify" }}>
            {description}
          </Card.Text>

          {/* GitHub Button, only renders if GITHUBLink is provided */}
          {GITHUBLink && (
            <Button
              variant="secondary"
              href={GITHUBLink}
              target="_blank"
              style={{ marginRight: "10px" }}
            >
              <FaGithub /> &nbsp; GitHub
            </Button>
          )}

          {/* Demo Button, only renders if DEMOLink is provided */}
          {DEMOLink && (
            <Button
              variant="primary"
              href={DEMOLink}
              target="_blank"
              style={{ marginLeft: "10px" }}
            >
              <CgWebsite /> &nbsp; Demo
            </Button>
          )}
        </Card.Body>
      </Card>

      {/* Modal to show the enlarged image */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="lg"
        centered
        dialogClassName="modal-override" // Custom class for styling
        contentClassName="modal-content-override" // Custom content class to remove white background
      >
        <Modal.Body style={{ position: "relative", padding: 0 }}>
          {/* Close Button placed on the top-right of the image */}
          <Button
            variant="link"
            onClick={handleCloseModal}
            aria-label="Close"
            className="btn-close"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 1,
            }}
          />
          <img
            src={imgPath}
            alt="Enlarged view"
            style={{ width: "100%", height: "auto", display: "block" }} // Makes the image responsive
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

ProjectCards.propTypes = {
  imgPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  DEMOLink: PropTypes.string,
  GITHUBLink: PropTypes.string,
};

export default ProjectCards;
