import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi, I'm <span className="purple">Harjot Singh Dhillon</span> from <span className="purple">Quebec, Canada</span>.
            <br /><br />
            I am currently studying computer science at <span className="purple">CEGEP Marie-Victorin</span> with a strong passion for software development.
            <br />
            I enjoy working on full-stack projects, exploring new technologies, and continuously enhancing my technical skills.
            <br />
            <br />
            Apart from coding, here are a few activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Gaming
            </li>
            <li className="about-activity">
              <ImPointRight /> Reading tech articles and books
            </li>
            <li className="about-activity">
              <ImPointRight /> Exploring new places
            </li>
          </ul>

          <p style={{ color: "#409cbb" }}>
            "Technology is best when it brings people together."
          </p>
          <footer className="blockquote-footer">Matt Mullenweg</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
