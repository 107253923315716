import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.png";
import Tilt from "react-parallax-tilt";
import { AiFillGithub, AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              Hello! I'm <b className="purple">Harjot Singh Dhillon</b>, a passionate and driven computer science student currently studying at <b>CEGEP Marie-Victorin</b>. 
              With a solid foundation in tech and an enthusiasm for problem-solving, I am excited to take on challenges in the world of software development.
              <br />
              <br />
              Over the years, I've developed a diverse set of skills and hands-on experience that makes me adaptable in various technical roles.
              <br />
              <br />

              <b className="purple">Skills and Expertise</b>
              <br />
              <ul>
                <li><b className="purple">Languages:</b> Fluent in C/C++, Python, and JavaScript (React).</li>
                <li><b className="purple">Frameworks & Tools:</b> Proficient in Spring Boot, Next.js, Django, and Node.js. I also work with AWS tools like EC2 and S3.</li>
                <li><b className="purple">Software Development Interests:</b> Full-stack development, cybersecurity, and IoT projects using devices like the Raspberry Pi.</li>
              </ul>

              <br />

              <b className="purple">My Vision</b>
              <br/>
              I believe technology has the potential to create positive change. My aim is to build software solutions that are accessible, efficient, and impactful. Whether it's crafting intuitive interfaces or diving into the backend, I'm always eager to explore new possibilities.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/hasilon88"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/harjot-singh-dhillon-a60438289/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://instagram.com/hasilon_singh?igshid=MTk0NTkyODZkYg=="
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;