import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiJava,
  DiBootstrap,
} from "react-icons/di";
import {
  SiPostgresql,
  SiSpring,
  SiAmazonaws,
  SiJira,
  SiConfluence,
  SiDjango,
  SiCplusplus,
  SiUnity,
  SiJenkins,
  SiGit,
  SiTailwindcss,
} from "react-icons/si";

function Techstack() {
  const techLinks = {
    javascript: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
    nodejs: "https://nodejs.org/",
    react: "https://reactjs.org/",
    django: "https://www.djangoproject.com/",
    mongodb: "https://www.mongodb.com/",
    postgresql: "https://www.postgresql.org/",
    python: "https://www.python.org/",
    spring: "https://spring.io/",
    java: "https://www.java.com/",
    aws: "https://aws.amazon.com/",
    bootstrap: "https://getbootstrap.com/",
    jira: "https://www.atlassian.com/software/jira",
    confluence: "https://www.atlassian.com/software/confluence",
    cplusplus: "https://isocpp.org/",
    unity: "https://unity.com/",
    bash: "https://www.gnu.org/software/bash/",
    jenkins: "https://www.jenkins.io/",
    git: "https://git-scm.com/",
    tailwindcss: "https://tailwindcss.com/",
  };

  const linkStyle = { color: "inherit", textDecoration: "none" };

  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.javascript} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <DiJavascript1 />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.nodejs} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <DiNodejs />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.react} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <DiReact />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.django} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiDjango />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.mongodb} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <DiMongodb />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.postgresql} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiPostgresql />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.python} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <DiPython />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.spring} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiSpring />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.java} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <DiJava />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.aws} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiAmazonaws />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.bootstrap} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <DiBootstrap />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.jira} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiJira />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.confluence} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiConfluence />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.cplusplus} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiCplusplus />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.unity} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiUnity />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.jenkins} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiJenkins />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.git} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiGit />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href={techLinks.tailwindcss} target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <SiTailwindcss />
        </a>
      </Col>
    </Row>
  );
}

export default Techstack;
